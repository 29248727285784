import React from "react";
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../constants/date";
import ArrowDisabled from "../images/san-jorge/arrow-disabled.png";
import DayPicker from "react-day-picker";

export const DateInputsComponents = ({
  showCalendar,
  activeCalendar,
  diaSeleccionado,
  horarioSeleccionado,
  conc,
  seleccionarDia,
  showHorario,
  horarios,
  activeHorario,
  seleccionarHorarios,
}) => {
  const fechaActual = new Date();

  return (
    <>
      <p className="label-input mt-4 mb-2">Fecha</p>
      <div onClick={showCalendar} className="containerSelect">
        {diaSeleccionado !== "" ? (
          <p className="fechaSeleccionada">
            {diaSeleccionado.toLocaleDateString()}
          </p>
        ) : (
          <p className="textSeleccione">Seleccione una fecha</p>
        )}
        <img
          src={ArrowDisabled}
          alt="arrow-disabled-kardur"
          className="arrowDisabled"
        />
      </div>
      <div className={activeCalendar ? "containerCalendar" : "displayNone"}>
        {activeCalendar ? (
          <>
            {conc ? (
              <DayPicker
                months={MONTHS}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                initialMonth={
                  fechaActual.getUTCDate() >= 20
                    ? new Date(2025, fechaActual.getMonth())
                    : null
                }
                fromMonth={new Date(2025, fechaActual.getMonth())}
                toMonth={
                  fechaActual.getUTCDate() >= 20
                    ? new Date(2025, fechaActual.getMonth() + 1)
                    : new Date(2025, fechaActual.getMonth())
                }
                onDayClick={(day) => seleccionarDia(day)}
                disabledDays={conc}
              />
            ) : (
              <p
                style={{
                  margin: "20px 0px 10px 0px",
                  fontSize: "12px",
                  color: "#393839",
                }}
              >
                Cargando dias disponibles, espere...
              </p>
            )}
          </>
        ) : null}
      </div>
      <p className="label-input mt-4 mb-2">Horario</p>
      <div
        onClick={diaSeleccionado !== "" ? showHorario : null}
        className="containerSelect"
      >
        {horarioSeleccionado !== "" ? (
          <div className="fechaSeleccionada">{horarioSeleccionado}</div>
        ) : (
          <div className="textSeleccione">Seleccione un horario</div>
        )}
        <img
          src={ArrowDisabled}
          alt="arrow-disabled-kardur"
          className="arrowDisabled"
        />
      </div>
      <div className={activeHorario ? "containerHorarios" : "displayNone"}>
        {activeHorario && horarios !== undefined
          ? horarios.map((info, i) => (
              <div key={i}>
                {info.available === true ? (
                  <div className="containerButtonsHorarios" key={info.horario}>
                    <div onClick={() => seleccionarHorarios(info.horario)}>
                      {info.horario}
                    </div>
                  </div>
                ) : (
                  <div
                    className="containerButtonsHorariosOcupado"
                    key={info.horario}
                  >
                    <div>{info.horario}</div>
                  </div>
                )}
              </div>
            ))
          : null}
      </div>
    </>
  );
};
